"use client";

import React, { useState, useEffect, ReactNode, useRef } from "react";
import styles from "./onlineRateTable.module.scss";
import classNames from "classnames";
import { useLocalStorage } from "../../../hooks/useLocalStorage/useLocalStorage";
import CurrencyDropdown from "../../CurrencyCalculator/currencySelector/currencyDropdown/currencyDropdown";
import { RemoveCustomRate } from "../RemoveCustomRate/RemoveCustomRate";

export type OnlineRateTableContainerProps = {
  currencyColumnLabel: string;
  sellRateColumnLabel: string;
  callToAction?: ReactNode;
  rateData: any;
};

const getCurrencyCodes = (rateList: any) => {
  return rateList.map((rate: any) => rate.currencyCode);
};

const TableRow = ({
  currency,
  sellRateColumnLabel,
  removeRateHandler = undefined,
}: {
  currency: any;
  sellRateColumnLabel: string;
  removeRateHandler?: any;
}) => {
  return (
    <div className={styles.row} role="row">
      <div role="cell" className={styles.currencyNameWrapper}>
        <div className={styles.currencyName}>
          <div className={styles.flagWrapper}>
            <img
              src={`https://storage.googleapis.com/jl-money-static-assets-prod/Flags/${currency.countryCode.toLowerCase()}.svg`}
              alt={`${currency.countryName} flag`}
            />
          </div>
          <div>
            {currency.currencyName} ({currency.currencyCode})
          </div>
          {currency.rateSale && (
            <div className={styles.rateSaleDesktop}>
              <img src="/icons/reward-points.svg" alt="rate sale badge" />
              <span className={styles.rateSaleText}>RATE SALE</span>
            </div>
          )}
        </div>
        <div
          className={classNames(
            styles.removeCustomRate,
            styles.removeCustomRateMobile
          )}
        >
          {removeRateHandler && (
            <RemoveCustomRate
              handleClick={() => removeRateHandler(currency.currencyCode)}
            />
          )}
        </div>
      </div>
      {currency.rateSale && (
        <div role="cell" className={styles.rateSaleMobile}>
          <img src="/icons/reward-points.svg" alt="rate sale badge" />
          <span className={styles.rateSaleText}>RATE SALE</span>
        </div>
      )}
      <div role="cell" className={styles.sellRateBlock}>
        <div className={styles.sellRateLabel}>{sellRateColumnLabel}</div>
        <div className={styles.sellRateValue}>{currency.sellRate}</div>
      </div>
      <div
        className={classNames(
          styles.removeCustomRate,
          styles.removeCustomRateDesktop
        )}
      >
        {removeRateHandler && (
          <RemoveCustomRate
            handleClick={() => removeRateHandler(currency.currencyCode)}
          />
        )}
      </div>
    </div>
  );
};

export default function OnlineRateTableCC({
  currencyColumnLabel,
  sellRateColumnLabel,
  callToAction,
  rateData,
}: OnlineRateTableContainerProps) {
  const [visibleRateData, setVisibleRateData] = useState<Array<any>>([]);
  const [customRateData, setCustomRateData] = useState<Array<any>>([]);
  const [showAddCurrency, setShowAddCurrency] = useState<boolean>(false);
  const [getCustomCurrencyList, setCustomCurrencyList] =
    useLocalStorage("jlm-currencies");

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);

  const AddCurrency = ({
    rates,
    addRateHandler,
    setShowAddCurrency,
    showAddCurrency,
  }: {
    rates: any;
    addRateHandler: any;
    setShowAddCurrency: any;
    showAddCurrency: boolean;
  }) => {
    if (rates.length === 0) return null;

    return (
      <div
        ref={dropdownWrapperRef}
        className={classNames(styles.dropdownAnchor)}
      >
        <div className={styles.addCurrencyRow}>
          <div className={styles.addCurrencyContent}>
            <button
              className={styles.addCurrencyButton}
              onClick={() => setShowAddCurrency(!showAddCurrency)}
            >
              <img src="/icons/add.svg" />
              <span>Add currency</span>
            </button>
          </div>
          {showAddCurrency && (
            <div className={styles.currencyDropdownContainer}>
              <CurrencyDropdown
                currencyList={rates}
                handleListItemClick={addRateHandler}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const updateCustomCurrencies = () => {
    const customCurrencyList = getCustomCurrencyList();
    setCustomRateData(
      rateData.rates.filter((rate: any) =>
        customCurrencyList.includes(rate.currencyCode)
      )
    );
  };

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (
      dropdownWrapperRef.current &&
      !dropdownWrapperRef.current.contains(event.target as Node)
    ) {
      setShowAddCurrency(false);
    }
  };

  useEffect(() => {
    if (rateData.rates) {
      if (window.innerWidth < 960) {
        setVisibleRateData(rateData.rates.slice(0, 6));
      } else {
        setVisibleRateData(rateData.rates.slice(0, 12));
      }
    }
    updateCustomCurrencies();

    document.addEventListener(
      "customCurrenciesChanged",
      updateCustomCurrencies
    );

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
      document.removeEventListener(
        "customCurrenciesChanged",
        updateCustomCurrencies
      );
    };
  }, []);

  const allVisibleRatesCodes = [
    ...getCurrencyCodes(visibleRateData),
    ...getCurrencyCodes(customRateData),
  ];
  const remainingRates = rateData.rates.filter(
    (rate: any) => !allVisibleRatesCodes.includes(rate.currencyCode)
  );

  const effectiveDateTime = rateData.effectiveDateTime.replace(" ", ", ");

  const addRateHandler = (event: MouseEvent | TouchEvent) => {
    const target = event.currentTarget as HTMLElement;
    const index = target.getAttribute("data-item-index");

    if (index === null) return;
    const numericIndex = Number(index);
    if (isNaN(numericIndex)) return;

    const newCustomRates = [...customRateData, remainingRates[index]];
    setCustomCurrencyList(JSON.stringify(getCurrencyCodes(newCustomRates)));
    setCustomRateData(newCustomRates);
    setShowAddCurrency(false);
    document.dispatchEvent(new CustomEvent("customCurrenciesChanged"));
  };

  const removeRateHandler = (currencyCodeToRemove: string) => {
    const newCustomRates = customRateData.filter(
      (rate: any) => rate.currencyCode !== currencyCodeToRemove
    );
    setCustomCurrencyList(JSON.stringify(getCurrencyCodes(newCustomRates)));
    setCustomRateData(newCustomRates);
    document.dispatchEvent(new CustomEvent("customCurrenciesChanged"));
  };

  return (
    <div>
      <div role="table" className={styles.rateTable}>
        <div role="row" className={classNames(styles.row, styles.heading)}>
          <div
            data-testid="currency-column-label"
            className={styles.headerCurrency}
            role="columnheader"
          >
            {currencyColumnLabel}
          </div>
          <div
            data-testid="rate-column-label"
            role="columnheader"
            className={styles.sellRate}
          >
            {sellRateColumnLabel}
          </div>
        </div>
        <div className={styles.body} role="rowgroup">
          {visibleRateData &&
            visibleRateData.map((value, index) => {
              return (
                <TableRow
                  currency={value}
                  sellRateColumnLabel={sellRateColumnLabel}
                  key={`online-rate-table-row-${index}`}
                />
              );
            })}
        </div>
        <div role="rowgroup" className={styles.customCurrencies}>
          {customRateData &&
            customRateData.length > 0 &&
            customRateData.map((value, index) => {
              return (
                <TableRow
                  currency={value}
                  sellRateColumnLabel={sellRateColumnLabel}
                  key={`online-rate-table-custom-row-${index}`}
                  removeRateHandler={() =>
                    removeRateHandler(value.currencyCode)
                  }
                />
              );
            })}
          <AddCurrency
            rates={remainingRates}
            addRateHandler={addRateHandler}
            setShowAddCurrency={setShowAddCurrency}
            showAddCurrency={showAddCurrency}
          />
        </div>
      </div>

      <div className={styles.effectiveDateWrapper}>
        <span>Rate correct as of {effectiveDateTime}</span>
      </div>
      {callToAction && (
        <div className={styles.callToAction}>{callToAction}</div>
      )}
    </div>
  );
}
