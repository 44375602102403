import React from 'react';
import styles from './currencyListItems.module.scss';
import classNames from 'classnames';

export type TTitleCurrencyListItem = {
  title: string;
  removeTopBorder?: boolean;
};

function TitleCurrencyListItem({
  removeTopBorder,
  title,
}: TTitleCurrencyListItem) {
  return (
    <li
      className={classNames(styles.listItem, styles.titleListItem, {
        [styles.clearBackgroundColour]: removeTopBorder,
      })}
      data-testid="title-list-item"
      role="menuitem"
    >
      <span
        className={classNames(styles.listItemContainer, styles.titleItem, {
          [styles.removeTopBorder]: removeTopBorder,
        })}
      >
        <span>{title}</span>
      </span>
    </li>
  );
}

export default TitleCurrencyListItem;
