const removeExtraDecimalCharacters = (str: string): string => {
  const sections = str.split(".");
  return [
    sections.slice(0, 2).join("."),
    sections.slice(2, sections.length).join(""),
  ].join("");
};

const trimToTwoDecimalPlaces = (str: string): string => {
  const sections = str.split(".");
  return sections[1].length > 2
    ? [sections[0], sections[1].slice(0, 2)].join(".")
    : sections.join(".");
};

export default function sanitiseCurrencyInput(
  currencySymbol: string,
  currencyValue: string
): string {
  const nonDecimalChars = /[^0-9.]/g;
  if (currencyValue === "") return `${currencySymbol}`;

  const valueWithoutSymbol = currencyValue.includes(currencySymbol)
    ? currencyValue.split(currencySymbol)[1]
    : currencyValue;

  let sanitisedValue = valueWithoutSymbol.replace(nonDecimalChars, "");

  if (sanitisedValue.split(".").length > 2) {
    sanitisedValue = removeExtraDecimalCharacters(sanitisedValue);
  }
  if (sanitisedValue.split(".").length === 2) {
    sanitisedValue = trimToTwoDecimalPlaces(sanitisedValue);
  }
  return `${currencySymbol}${sanitisedValue}`;
}
