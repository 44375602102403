"use client";

import React, { forwardRef, useEffect } from "react";
import TitleCurrencyListItem from "../currencyListItems/titleCurrencyListItem";
import CurrencyListItem from "../currencyListItems/currencyListItem";
import styles from "./currencyDropdown.module.scss";
import getFlagUrl from "@JohnLewisPartnership/jlfs-ui-library/dist/utils/getFlagUrl";
import { ParsedRate } from "../../../../utils/getRates/getRates";

export type TCurrencyDropdownProps = {
  currencyList: Array<ParsedRate>;
  handleClick?: (event: any) => void;
  handleListItemClick?: (event: any) => void;
};

function CurrencyDropdown(
  {
    currencyList,
    handleListItemClick,
    handleClick,
  }: TCurrencyDropdownProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  useEffect(() => {
    handleClick && document.addEventListener("mousedown", handleClick);
    return () => {
      handleClick && document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <ul
      className={styles.dropdown}
      data-testid="currency-list"
      aria-labelledby="dropdown-button"
      role="menu"
      ref={ref}
    >
      <TitleCurrencyListItem
        title="Select Currency"
        removeTopBorder
      ></TitleCurrencyListItem>
      {currencyList.map((rate, index) => (
        <CurrencyListItem
          currencyName={`${rate.currencyName} (${rate.currencyCode})`}
          countryName={rate.countryName}
          flagSrc={getFlagUrl(rate.countryCode)}
          key={rate.currencyCode}
          itemIndex={index}
          itemType="popular"
          clickHandler={handleListItemClick}
        ></CurrencyListItem>
      ))}
    </ul>
  );
}

export default forwardRef(CurrencyDropdown);
