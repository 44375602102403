"use client";

import styles from "./currencySelector.module.scss";
import React, { useRef, useState } from "react";
import CurrencyDropdown from "./currencyDropdown/currencyDropdown";
import Text from "@JohnLewisPartnership/jlfs-ui-library/dist/components/inputs/text/text";
import getFlagUrl from "@JohnLewisPartnership/jlfs-ui-library/dist/utils/getFlagUrl";
import { ParsedRate } from "../../../utils/getRates/getRates";

export type TCurrencySelector = {
  label?: string;
  currencyList?: Array<ParsedRate>;
  selectedCurrency: ParsedRate;
  inputValue?: string;
  dropdownEnabled?: boolean;
  inputValueUpdated?: (value: string) => void;
  selectedCurrencyUpdated?: (currency: ParsedRate) => void;
  focusUpdated?: (value: string) => void;
  error?: string;
};

export default function CurrencySelector({
  label,
  currencyList = [],
  selectedCurrency,
  inputValue,
  dropdownEnabled = true,
  inputValueUpdated,
  selectedCurrencyUpdated,
  focusUpdated,
  error,
}: TCurrencySelector) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  const toggleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleKeyDown = (event: any): void => {
    event.preventDefault();
    if (event.key === "Enter" || event.key === " ") {
      toggleDropdown();
    } else if (event.key === "Escape") {
      setDropdownIsOpen(false);
    }
  };

  const handleOnChange = (event: any): void => {
    inputValueUpdated && inputValueUpdated(event?.target?.value);
  };

  const handleOnFocus = (event: any): void => {
    focusUpdated && focusUpdated(event?.target?.value);
  };

  const handleListItemClick = (event: any): void => {
    const index = event.currentTarget.getAttribute("data-item-index");
    const itemType = event.currentTarget.getAttribute("data-item-type");
    if (index && itemType) {
      const selectedCurrency = currencyList[index];
      selectedCurrencyUpdated && selectedCurrencyUpdated(selectedCurrency);
      setDropdownIsOpen(false);
    }
  };

  const handleClickOutsideDropdown = (event: any) => {
    if (dropdownIsOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  return (
    <>
      <div className={styles.currencySelector}>
        <Text
          name="Currency Input"
          helpText={label ?? "What you’ll get in your currency"}
          onChange={handleOnChange}
          value={inputValue}
          inputMode="decimal"
          error={error}
          onFocus={handleOnFocus}
        ></Text>
        <div
          tabIndex={0}
          aria-haspopup="true"
          aria-expanded={dropdownIsOpen}
          aria-controls="dropdown-menu"
          data-testid="selected-currency"
          onKeyDown={handleKeyDown}
          onMouseDown={toggleDropdown}
          className={styles.currencyIndicator}
        >
          <span className={styles.currencyContainer}>
            <div className={styles.flagWrapper}>
              <img
                src={getFlagUrl(selectedCurrency?.countryCode ?? "")}
                alt={`Flag of ${selectedCurrency?.countryName}`}
              ></img>
            </div>
            <span className={styles.currencyCode}>
              {selectedCurrency?.currencyCode ?? ""}
            </span>
          </span>
          <span className={styles.iconContainer}>
            {dropdownEnabled && (
              <img
                data-testid="menu-indicator"
                src={
                  dropdownIsOpen
                    ? "/icons/chevron-up.svg"
                    : "/icons/chevron-down.svg"
                }
                alt=""
              ></img>
            )}
          </span>
        </div>
        {dropdownIsOpen && dropdownEnabled ? (
          <CurrencyDropdown
            currencyList={currencyList}
            handleListItemClick={handleListItemClick}
            handleClick={handleClickOutsideDropdown}
            ref={dropdownRef}
          ></CurrencyDropdown>
        ) : null}
      </div>
    </>
  );
}
