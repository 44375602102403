export default function foreignCurrencyUrlBuilder (
    currencyCode: string,
    pounds: string,
    selectedCurrency: string,
    sellRate: string,
    timeStamp: string
  ): string {
    const ratesUrl = new URL(
      "https://foreigncurrency.johnlewisfinance.com/foreign-currency-converter"
    );
    ratesUrl.searchParams.append("product", `${currencyCode}N`);
    ratesUrl.searchParams.append("denomination", "mixed");
    ratesUrl.searchParams.append("direction", "BASEtoFX");
    ratesUrl.searchParams.append("exchange_rates_timestamp", timeStamp);
    ratesUrl.searchParams.append("rate", sellRate);
    ratesUrl.searchParams.append(
      "intcmp",
      "ordernowbutton-pagename-currencyconverter"
    );
    ratesUrl.searchParams.append("product_type_selector", "N");
    ratesUrl.searchParams.append("currency_selector", currencyCode);
    ratesUrl.searchParams.append("amount", pounds);
    ratesUrl.searchParams.append("fres_currency_amount_to", selectedCurrency);
    return ratesUrl.toString();
  }