"use client";

import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import Image from "next/image";

import { colourMap } from "@JohnLewisPartnership/jlfs-ui-library/dist/utils/colours";

import {
  getRemainingTime,
  extractDays,
  extractHours,
  extractMinutes,
  extractSeconds,
  formatToDoubleDigit,
  formatDate,
} from "./extractTimeUnits";
// @ts-ignore
import heritageIcon from "./reward-points-heritage.svg";
// @ts-ignore
import neonIcon from "./reward-points-neon.svg";
import styles from "./RateSaleCountdown.module.scss";

export type TRateSaleCountdown = {
  style: string | undefined;
  title: string | undefined;
  endDate: string;
  footerLabel: string | undefined;
};

export default function RateSaleCountdown(props: TRateSaleCountdown) {
  const { style, title, endDate, footerLabel } = props;

  const [showDays, setShowDays] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remainingTime = getRemainingTime(endDate);
      const days = extractDays(remainingTime);
      const hours = extractHours(remainingTime);
      const mins = extractMinutes(remainingTime);
      const secs = extractSeconds(remainingTime);

      setTimeLeft({ days, hours, mins, secs });
      setShowDays(days > 0);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  const { days, hours, mins, secs } = timeLeft;
  const separator = (
    <div aria-hidden="true" className={styles.separator}>
      :
    </div>
  );

  const sectionStyle = useMemo(
    () =>
      ({
        "--backgroundColour":
          style === "neon green style"
            ? colourMap("Neon Pastel Green")
            : colourMap("Heritage Green"),
        "--textColour":
          style === "neon green style"
            ? colourMap("Heritage Green")
            : colourMap("Neon Pastel Green"),
      } as React.CSSProperties),
    [style]
  );

  return (
    <div
      className={styles.rateSaleCountdown}
      style={sectionStyle}
      data-testid="countdown-container"
    >
      <div className={styles.title}>
        {style === "neon green style" ? (
          <Image
            src={heritageIcon}
            width={15}
            height={15}
            alt=""
            data-testid="heritage-icon"
          />
        ) : (
          <Image
            src={neonIcon}
            width={15}
            height={15}
            alt=""
            data-testid="neon-icon"
          />
        )}
        <span>{title}</span>
      </div>
      <div
        role="timer"
        className={styles.countdownContainer}
        data-testid="countdown-timer"
      >
        {showDays && (
          <>
            <div className={styles.timeUnitContainer}>
              <div className={styles.timeValue}>
                {formatToDoubleDigit(days)}
              </div>
              <div className={styles.timeUnit}>DAYS</div>
            </div>
            {separator}
          </>
        )}
        <div className={styles.timeUnitContainer}>
          <div className={styles.timeValue}>{formatToDoubleDigit(hours)}</div>
          <div className={styles.timeUnit}>HOURS</div>
        </div>
        {separator}
        <div className={styles.timeUnitContainer}>
          <div className={styles.timeValue}>{formatToDoubleDigit(mins)}</div>
          <div className={classNames(styles.timeUnit, styles.mobileText)}>
            MINS
          </div>
          <div className={classNames(styles.timeUnit, styles.desktopText)}>
            MINUTES
          </div>
        </div>
        {!showDays ? (
          <>
            {separator}
            <div className={styles.timeUnitContainer}>
              <div className={styles.timeValue}>
                {formatToDoubleDigit(secs)}
              </div>
              <div className={classNames(styles.timeUnit, styles.mobileText)}>
                SECS
              </div>
              <div className={classNames(styles.timeUnit, styles.desktopText)}>
                SECONDS
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className={styles.footerLabel}>
        {getRemainingTime(endDate) > 0 ? formatDate(endDate) : footerLabel}
      </div>
    </div>
  );
}
