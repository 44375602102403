import React from "react";
import styles from "./currencyListItems.module.scss";

export type TCurrencyListItem = {
  currencyName: string;
  countryName: string;
  flagSrc: string;
  itemIndex: number;
  itemType: string;
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
  keyDownHandler?: (event: React.KeyboardEvent<HTMLLIElement>) => void;
};

function CurrencyListItem({
  itemIndex,
  itemType,
  flagSrc,
  countryName,
  currencyName,
  clickHandler,
}: TCurrencyListItem) {
  return (
    <li
      data-testid="currency-list-item"
      onClick={clickHandler}
      data-item-index={itemIndex}
      data-item-type={itemType}
      role="menuitem"
      className={styles.listItem}
    >
      <span className={styles.listItemContainer}>
        <div className={styles.flagWrapper}>
          <img
            src={flagSrc}
            className={styles.currencyFlag}
            alt={`Flag of ${countryName}`}
          />
        </div>
        <span className={styles.currencyInfo}>{currencyName}</span>
      </span>
    </li>
  );
}

export default CurrencyListItem;
