import styles from "./RemoveCustomRate.module.scss";

type Props = {
  handleClick: () => void;
};
export const RemoveCustomRate = ({ handleClick }: Props) => {
  return (
    <button className={styles.removeRateIcon} onClick={handleClick} data-testid="remote-rate-button">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2923 11.9994L7.3125 8.01961L8.01961 7.3125L11.9994 11.2923L15.9792 7.3125L16.6863 8.01961L12.7065 11.9994L16.6863 15.9792L15.9792 16.6863L11.9994 12.7065L8.01961 16.6863L7.3125 15.9792L11.2923 11.9994Z"
          fill="white"
        />
      </svg>
    </button>
  );
};
