"use client";

import React, { useState, useEffect, ReactNode, useRef } from "react";
import styles from "./BureauRateTable.module.scss";
import classNames from "classnames";
import { ParsedRate } from "../../../utils/getRates/getRates";
import { useLocalStorage } from "../../../hooks/useLocalStorage/useLocalStorage";
import CurrencyDropdown from "../../CurrencyCalculator/currencySelector/currencyDropdown/currencyDropdown";
import { RemoveCustomRate } from "../RemoveCustomRate/RemoveCustomRate";

export type BureauRateTableProps = {
  currencyColumnLabel: string;
  sellRatesHeading: string;
  sellRate1ColumnLabel: string;
  sellRate2ColumnLabel: string;
  sellRate3ColumnLabel: string;
  buyRateColumnLabel: string;
  hideBuyColumn?: boolean;
  callToAction?: ReactNode;
  rateData: any;
};

const getCurrencyCodes = (rateList: any) => {
  return rateList.map((rate: any) => rate.currencyCode);
};

type TableRowProps = {
  currency: {
    countryCode: string;
    countryName: string;
    currencyName: string;
    currencyCode: string;
    rateSale: string;
    sellRate: string;
    sellRate2: string;
    sellRate3: string;
    buyRate: string;
  };
  sellRatesHeading: string;
  sellRate1ColumnLabel: string;
  sellRate2ColumnLabel: string;
  sellRate3ColumnLabel: string;
  buyRateColumnLabel: string;
  removeRateHandler?: (currencyCode: string) => void;
};

const TableRow = ({
  currency,
  sellRatesHeading,
  sellRate1ColumnLabel,
  sellRate2ColumnLabel,
  sellRate3ColumnLabel,
  buyRateColumnLabel,
  // hideBuyColumn = false,
  removeRateHandler = undefined,
}: TableRowProps): React.JSX.Element => {
  return (
    <div role="row" className={styles.row}>
      <div role="cell">
        <div className={styles.currencyNameWrapper}>
          <div className={styles.currencyName}>
            <div className={styles.flagWrapper}>
              <img
                src={`https://storage.googleapis.com/jl-money-static-assets-prod/Flags/${currency.countryCode.toLowerCase()}.svg`}
                alt={`${currency.countryName} flag`}
              />
            </div>
            <div>
              {currency.currencyName} ({currency.currencyCode})
            </div>
            {currency.rateSale && (
              <div className={styles.rateSaleDesktop}>
                <img src="/icons/reward-points.svg" alt="rate sale badge" />
                <span className={styles.rateSaleText}>RATE SALE</span>
              </div>
            )}
          </div>
          <div
            className={classNames(
              styles.removeCustomRate,
              styles.removeCustomRateMobile
            )}
          >
            {removeRateHandler && (
              <RemoveCustomRate
                handleClick={() => removeRateHandler(currency.currencyCode)}
              />
            )}
          </div>
        </div>
        {currency.rateSale && (
          <div className={styles.rateSaleMobile}>
            <img src="/icons/reward-points.svg" alt="rate sale badge" />
            <span className={styles.rateSaleText}>RATE SALE</span>
          </div>
        )}
      </div>
      <div role="cell" className={styles.sellRateHeadingMobile}>
        {sellRatesHeading}
      </div>
      <div role="cell" className={styles.sellRateBlock}>
        <div className={styles.sellRateLabel}>{sellRate1ColumnLabel}</div>
        <div className={styles.sellRateValue}>{currency.sellRate}</div>
      </div>
      <div role="cell" className={styles.sellRateBlock}>
        <div className={styles.sellRateLabel}>{sellRate2ColumnLabel}</div>
        <div className={styles.sellRateValue}>{currency.sellRate2}</div>
      </div>
      <div role="cell" className={styles.sellRateBlock}>
        <div className={styles.sellRateLabel}>{sellRate3ColumnLabel}</div>
        <div className={styles.sellRateValue}>{currency.sellRate3}</div>
      </div>
      <div role="cell" className={styles.buyRateBlock}>
        <div className={styles.buyRateLabel}>{buyRateColumnLabel}</div>
        <div className={styles.buyRateValue}>{currency.buyRate}</div>
      </div>
      <div
        className={classNames(
          styles.removeCustomRate,
          styles.removeCustomRateDesktop
        )}
      >
        {removeRateHandler && (
          <RemoveCustomRate
            handleClick={() => removeRateHandler(currency.currencyCode)}
          />
        )}
      </div>
    </div>
  );
};

export default function BureauRateTableCC({
  currencyColumnLabel,
  sellRatesHeading,
  sellRate1ColumnLabel,
  sellRate2ColumnLabel,
  sellRate3ColumnLabel,
  buyRateColumnLabel,
  hideBuyColumn = false,
  callToAction,
  rateData,
}: BureauRateTableProps) {
  const [visibleRateData, setVisibleRateData] = useState<ParsedRate[]>([]);
  const [customRateData, setCustomRateData] = useState<Array<any>>([]);
  const [showAddCurrency, setShowAddCurrency] = useState<boolean>(false);
  const [getCustomCurrencyList, setCustomCurrencyList] =
    useLocalStorage("jlm-currencies");

  const updateCustomCurrencies = () => {
    const customCurrencyList = getCustomCurrencyList();

    let customRate = rateData.rates.filter((rate: any) =>
      customCurrencyList.includes(rate.currencyCode)
    );

    if (visibleRateData) {
      const visibleCurrencies = visibleRateData.map(
        (item) => item.currencyCode
      );
      customRate = customRate.filter(
        (rate: any) => !visibleCurrencies.includes(rate.currencyCode)
      );
    }

    setCustomRateData(customRate);
  };

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);

  const AddCurrency = ({
    rates,
    addRateHandler,
    setShowAddCurrency,
    showAddCurrency,
  }: {
    rates: any;
    addRateHandler: any;
    setShowAddCurrency: any;
    showAddCurrency: boolean;
  }) => {
    if (rates.length === 0) return null;

    return (
      <div
        ref={dropdownWrapperRef}
        className={classNames(styles.dropdownAnchor)}
      >
        <div className={styles.addCurrencyRow}>
          <div className={styles.addCurrencyContent}>
            <button
              className={styles.addCurrencyButton}
              onClick={() => setShowAddCurrency(!showAddCurrency)}
            >
              <img src="/icons/add.svg" />
              <span>Add currency</span>
            </button>
          </div>
          {showAddCurrency && (
            <div className={styles.currencyDropdownContainer}>
              <CurrencyDropdown
                currencyList={rates}
                handleListItemClick={addRateHandler}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (
      dropdownWrapperRef.current &&
      !dropdownWrapperRef.current.contains(event.target as Node)
    ) {
      setShowAddCurrency(false);
    }
  };

  useEffect(() => {
    if (rateData.rates) {
      if (window.innerWidth < 960) {
        setVisibleRateData(rateData.rates.slice(0, 6));
      } else {
        setVisibleRateData(rateData.rates.slice(0, 12));
      }
    }
    updateCustomCurrencies();
  }, []);

  useEffect(() => {
    document.addEventListener(
      "customCurrenciesChanged",
      updateCustomCurrencies
    );

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
      document.removeEventListener(
        "customCurrenciesChanged",
        updateCustomCurrencies
      );
    };
  }, [visibleRateData]);

  useEffect(() => {
    updateCustomCurrencies();
  }, [visibleRateData]);

  const effectiveDateTime = rateData.effectiveDateTime.replace(" ", ", ");

  const allVisibleRatesCodes = [
    ...getCurrencyCodes(visibleRateData),
    ...getCurrencyCodes(customRateData),
  ];

  const remainingRates = rateData.rates.filter(
    (rate: any) => !allVisibleRatesCodes.includes(rate.currencyCode)
  );

  const addRateHandler = (event: MouseEvent | TouchEvent) => {
    const target = event.currentTarget as HTMLElement;
    const index = target?.getAttribute("data-item-index");

    if (index === null) return;
    const numericIndex = Number(index);
    if (isNaN(numericIndex)) return;

    const newCustomRates = [...customRateData, remainingRates[index]];

    const customCurrencyList = getCustomCurrencyList();
    const updateCurrencyList = customCurrencyList.length > 0
      ? [...JSON.parse(customCurrencyList), remainingRates[index]?.currencyCode]
      : [remainingRates[index]?.currencyCode];

    setCustomCurrencyList(JSON.stringify(updateCurrencyList));
    setCustomRateData(newCustomRates);
    setShowAddCurrency(false);
    document.dispatchEvent(new CustomEvent("customCurrenciesChanged"));
  };

  const removeRateHandler = (currencyCodeToRemove: string) => {
    const newCustomRates = customRateData.filter(
      (rate: any) => rate.currencyCode !== currencyCodeToRemove
    );

    const customCurrencyList = getCustomCurrencyList();
    const updateCurrencyList = JSON.parse(customCurrencyList).filter(
      (currency: string) => currency !== currencyCodeToRemove
    );

    setCustomCurrencyList(JSON.stringify(updateCurrencyList));
    setCustomRateData(newCustomRates);
    document.dispatchEvent(new CustomEvent("customCurrenciesChanged"));
  };

  return (
    <div>
      <div
        className={classNames(
          styles.rateTable,
          hideBuyColumn ? styles.removeBuyRate : null
        )}
        role="table"
      >
        <div role="row" className={styles.heading}>
          <div
            data-testid="currency-column-label"
            className={styles.headerCurrency}
            role="columnheader"
            aria-sort="none"
          >
            {currencyColumnLabel}
          </div>
          <div
            role="columnheader"
            data-testid="sell-rates-heading"
            className={styles.sellRate}
          >
            {sellRatesHeading}
          </div>
          <div
            role="columnheader"
            data-testid="buy-column-label"
            className={styles.buyRate}
          >
            {buyRateColumnLabel}
          </div>
          <div
            role="columnheader"
            data-testid="under500-column-label"
            className={styles.under500}
          >
            {sellRate1ColumnLabel}
          </div>
          <div
            role="columnheader"
            data-testid="over500-column-label"
            className={styles.over500}
          >
            {sellRate2ColumnLabel}
          </div>
          <div
            role="columnheader"
            data-testid="over1000-column-label"
            className={styles.over1000}
          >
            {sellRate3ColumnLabel}
          </div>
        </div>
        <div role="rowgroup" className={styles.body}>
          {visibleRateData.map((value, index) => (
            <TableRow
              // @ts-ignore
              currency={value}
              key={`online-rate-table-custom-row-${index}`}
              sellRatesHeading={sellRatesHeading}
              sellRate1ColumnLabel={sellRate1ColumnLabel}
              sellRate2ColumnLabel={sellRate2ColumnLabel}
              sellRate3ColumnLabel={sellRate3ColumnLabel}
              buyRateColumnLabel={buyRateColumnLabel}
              // hideBuyColumn={hideBuyColumn}
            />
          ))}
        </div>
        <div role="rowgroup">
          {customRateData.map((value, index) => (
            <TableRow
              currency={value}
              key={`online-rate-table-custom-row-${index}`}
              sellRatesHeading={sellRatesHeading}
              sellRate1ColumnLabel={sellRate1ColumnLabel}
              sellRate2ColumnLabel={sellRate2ColumnLabel}
              sellRate3ColumnLabel={sellRate3ColumnLabel}
              buyRateColumnLabel={buyRateColumnLabel}
              // hideBuyColumn={hideBuyColumn}
              removeRateHandler={() => removeRateHandler(value.currencyCode)}
            />
          ))}
          <AddCurrency
            rates={remainingRates}
            addRateHandler={addRateHandler}
            setShowAddCurrency={setShowAddCurrency}
            showAddCurrency={showAddCurrency}
          />
        </div>
      </div>
      <div className={styles.effectiveDateWrapper}>
        <span>Rate correct as of {effectiveDateTime}</span>
      </div>
      {callToAction && (
        <div className={styles.callToAction}>{callToAction}</div>
      )}
    </div>
  );
}
