import { useEffect, useRef } from 'react';

export const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
    const timeOutRef = useRef<any>(null);

    const reset = (...args: any[]) => {
        if(timeOutRef.current) {
            clearTimeout(timeOutRef.current);
        }
        timeOutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }

    const cancel = () => {
        if(timeOutRef.current) {
            clearTimeout(timeOutRef.current);
        }
    }

    useEffect(() => {
        return () => {
            if(timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        }
    }, []);

    return { reset, cancel };
}