export const useLocalStorage = (key: string): [any, any] => {
  const getLocalStorageItem = () => {
    return localStorage.getItem(key) || [];
  };
  const setLocalStorageItem = (value: string) => {
    localStorage.setItem(key, value);
  };

  return [getLocalStorageItem, setLocalStorageItem];
};
