export const formatToDoubleDigit = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const formatDate = (dateInput: string | Date): string => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateInput);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];

  const formattedHours = hours % 12 || 12; // Converting 24-hour format to 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const period = hours >= 12 ? "pm" : "am";

  return `At ${formattedHours}.${formattedMinutes}${period} on the ${day} ${month}`;
};

export const getRemainingTime = (endDate: string | Date): number => {
  const currentTime = new Date().getTime();
  const endTime = new Date(endDate).getTime();

  if (endTime && typeof endTime === "number") {
    if (endTime >= currentTime) {
      return endTime - currentTime;
    } else {
      return 0;
    }
  }
  return 0;
};

export const extractDays = (remainingTime: number = 0): number => {
  const daysFloat = remainingTime / (24 * 60 * 60 * 1000);
  const daysInt = Math.floor(daysFloat);
  return daysInt;
};

export const extractHours = (remainingTime: number = 0): number => {
  const hoursFloat = (remainingTime / (60 * 60 * 1000)) % 24;
  const hoursInt = Math.floor(hoursFloat);
  return hoursInt;
};

export const extractMinutes = (remainingTime: number = 0): number => {
  const minsFloat = (remainingTime / (60 * 1000)) % 60;
  const minsInt = Math.floor(minsFloat);
  return minsInt;
};

export const extractSeconds = (remainingTime: number = 0): number => {
  const secondsFloat = (remainingTime / 1000) % 60;
  const secondsInt = Math.floor(secondsFloat);
  return secondsInt;
};
